import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'

export class APIService {

  newContactUser (
      firstName, lastName, email, phoneNumber, message,
    ) {
    const url = apiBaseUrl + `/api/contact`
    var headers = {
      'Content-Type': 'application/json',
    }
    return axios.post(
      url,
      {
        'first_name': firstName,
        'last_name': lastName,
        'email': email,
        'phone_number': phoneNumber,
        'message': message,
      },
      {
        'headers': headers,
      }
    )
  }
}
