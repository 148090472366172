<template>
  <div class="Contact" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Besoin d'informations ? Contactez-nous</h1>
        <div class="form_div">
          <div class="form_content">
            <div>
              <b-modal ref="contactAPIErrorModal" hide-footer title="Une erreur s'est produite">
                <div class="text-center">
                  <p>Une erreur s'est produite lors de l'envoi de votre demande de contact. Veuillez vérifier vos
                    informations et réessayer.
                  </p>
                  <b-button class="mt-3" variant="outline-success" @click="hideContactAPIErrorModalModal">Fermer</b-button>
                </div>
              </b-modal>


              <div v-if="isContacted">
                <div class="text-center">
                  <div class="mail-sent-img">
                    <b-img :src="require('@/static/images/send.svg')" style="max-width: 20%;'"
                           fluid alt="Message envoyé"  />
                  </div>
                  <h2 class="mt-3">
                    Votre demande a bien été communiquée
                  </h2>
                  <strong>  Votre demande a bien été envoyée. Nous revenons vers vous au plus vite.</strong>
                </div>
                <div class="row mt-2">
                  <div class="col-12 text-center">
                    <b-button
                      block variant="outline-dark" class="mt-2 btn-lg"
                      @click='goToHome'>
                      Retour à l'accueil
                    </b-button>
                  </div>
                </div>
              </div>
              <div v-else>
                <div>
                  <p>
                    Besoin d'informations ou d'un devis ?
                    Renseignez vos coordonnées et nous vous recontactons au plus vite.
                  </p>
                </div>
                <div class="row mt-4">
                  <div class="col-sm-6">
                    <b-form-group id="firstName-group" label="Votre prénom" label-for="firstName-input" class="mandatory-input">
                      <b-form-input
                        v-model="firstName" type="text"
                        maxlength="30"
                        placeholder="Prénom"
                        id="firstName-input"
                        @input="$v.firstName.$touch()"
                        :state="$v.firstName.$dirty ? !$v.firstName.$error : null">
                      </b-form-input>
                      <div class="form-error" v-if="$v.firstName.$error">Votre prénom doit comporter entre 2 et 30 caractères</div>
                    </b-form-group>
                  </div>
                  <div class="col-sm-6">
                    <b-form-group id="lastName-group" label="Votre nom" label-for="lastName-input" class="mandatory-input">
                      <b-form-input
                        v-model="lastName" type="text"
                        maxlength="150"
                        placeholder="Nom"
                        id="lastName-input"
                        @input="$v.lastName.$touch()"
                        :state="$v.lastName.$dirty ? !$v.lastName.$error : null">
                      </b-form-input>
                      <div class="form-error" v-if="$v.lastName.$error">Votre nom doit comporter entre 2 et 150 caractères</div>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-group id="email-group" label="Votre email" label-for="email-input" class="mandatory-input">
                      <b-form-input
                        v-model="email" type="email"
                        maxlength="150"
                        placeholder="Email"
                        id="email-input"
                        @input="$v.email.$touch()"
                        :state="$v.email.$dirty ? !$v.email.$error : null">
                      </b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-6">
                    <b-form-group id="phone-number-group" label="Votre numéro de téléphone" label-for="phone-number-input" class="mandatory-input">
                      <b-form-input
                        id="phone-number-input"
                        type="tel" maxlength="10"
                        v-model.trim.lazy="phoneNumber"
                        @input="$v.phoneNumber.$touch()"
                        :state="$v.phoneNumber.$dirty ? !$v.phoneNumber.$error : null"
                        placeholder="Entrez votre numéro de téléphone">
                      </b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <b-form-group id="message-group" label="Objet de votre demande" label-for="message-input" class="mandatory-input">
                  <b-form-textarea
                    v-model="message" type="text"
                    maxlength="500"
                    id="message-input"
                    rows="5"
                    @input="$v.message.$touch()"
                    :state="$v.message.$dirty ? !$v.message.$error : null">
                  </b-form-textarea>
                  <div class="form-error" v-if="$v.message.$error">Votre message doit comporter entre 2 et 499 caractères</div>
                </b-form-group>

                <b-button
                  block :variant="contactButtonStyle" class="mt-2"
                  @click='contact'
                  :disabled="$v.firstName.$invalid || $v.lastName.$invalid || $v.email.$invalid || $v.phoneNumber.$invalid || $v.message.$invalid">
                    <b-spinner small label="access information Spinner" v-if="contactInProcess"></b-spinner> {{ contactButtonText }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
const formTitleComponent = () => import('@/components/formTitleComponent')
import { APIService } from '@/api/APIService'
const apiService = new APIService()

export default {
  name: 'Contact',
  components: {
    formTitleComponent,
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      contactInProcess: false,
      contactButtonText: "Envoyez votre demande de contact",
      contactButtonStyle: "outline-dark",

      isContacted: false,

      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      message: ""
    }
  },
  validations: {
    firstName: {
      minLength: minLength(2),
      maxLength: maxLength(30),
      required
    },
    lastName: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required
    },
    email: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required,
      email
    },
    phoneNumber: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10)
    },
    message: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(499)
    },
  },
  methods: {
    contact () {
      this.contactInProcess = true
      this.contactButtonText = "Demande de contact en cours"
      this.contactButtonStyle = 'secondary'
      apiService.newContactUser(
        this.firstName, this.lastName, this.email, this.phoneNumber, this.message)
      .then(() => {
        this.isContacted = true
      })
      .catch((error) => {
        this.$refs.contactAPIErrorModal.show()
        throw new Error('Contact API Error' + error)
      })
      .finally(() => {
        this.contactInProcess = false
        this.contactButtonText = "Envoyez votre demande de contact"
        this.contactButtonStyle = 'outline-dark'
      })

    },
    hideContactAPIErrorModalModal () {
      this.$refs.contactAPIErrorModal.hide()
    },

    goToHome () {
      this.$router.push('/')
    }
  }
}
</script>
